.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: white;
    width: 40rem;
    height: fit-content;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
  }
  
  .card img {
    margin-top: -20%;
    width: 100%;
    border-radius: 20px;
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 1rem;
    color: #01304A;
    text-align: center;
    font-size: large;
    font-weight: bold;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  