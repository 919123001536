body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'gothic';
  font-style: normal;
  font-weight: normal;
  src: local('gothic'), url('./font/gothic.woff') format('woff');
}

::-moz-selection {
  color: rgb(1, 48, 74);
  background: #FFBDA6;
}

::selection {
  color: rgb(1, 48, 74);
  background: #FFBDA6;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: white; 
}

::-webkit-scrollbar-thumb {
  background: #01304A; 
  border: 0px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media ( -webkit-min-device-pixel-ratio: 1.25 ) {
    body {
      zoom: 0.8;
    }
    .login {
      height: 125vh !important;
    }
    .forceHeight {
      height: 125vh !important;
    }
}

.login {
  position: relative;
  background: #01304A;
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-div {
  max-width: 340px !important;
  margin: auto !important;
  margin-top: 175px;
}

.swiper-pagination {
  bottom: -5px !important
}

:root {
  /** * THEME LIGHT */
  --main-bg-color-light: rgba(172, 205, 216);
  /* tabs colors */
  --tabs-header-bg-color: rgb(231, 234, 236);
  --tabs-bg-color: var(--tabs-header-bg-color);
  --tabs-selected-bg-color: var(--main-bg-color-light);
  --tabs-hover-bg-color: rgb(240, 243, 245);
  --tabs-text-color: rgb(88, 92, 95);
  --tabs-hover-text-color: rgb(88, 92, 95);
  --tabs-selected-text-color: rgb(47, 48, 49);
  --tabs-height: 2.2rem;
  /** * THEME DARK */
  --main-bg-color-dark: rgb(49, 53, 57);
  /* tabs colors */
  --dark-tabs-header-bg-color: rgb(31, 32, 35);
  --dark-tabs-bg-color: var(--main-bg-color-dark);
  --dark-tabs-selected-bg-color: var(--dark-tabs-header-bg-color);
  --dark-tabs-hover-bg-color: #25282b;
  --dark-tabs-text-color: rgb(134, 136, 138);
  --dark-tabs-hover-text-color: rgb(255, 255, 255);
  --dark-tabs-selected-text-color: rgb(221, 226, 231);
}
.sd-tabs {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-wrap: nowrap;
  margin: 0;
  padding-top: 1rem;
  position: relative;
  height: 100%;
}
.sd-tab-radio {
  position: absolute;
  opacity: 0;
}
.sd-tabs > .sd-tab-label {
  padding: 3px 5px 5px 10px;
  display: flex;
  align-items: center;
  min-width: 400px;
  width: 400px;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 2;
  max-height: var(--tabs-height);
  text-align: center;
  /* transition: background-color ease .2s, box-shadow ease .2s;
  */
  cursor: pointer;
  flex: 1;
}
.sd-tabs[dark] > .sd-tab-label {
  background-color: var(--dark-tabs-bg-color);
}
.sd-tabs > .sd-tab-label:hover {
  background-color: var(--tabs-hover-bg-color);
  color: var(--tabs-hover-text-color);
  z-index: 3;
}
.sd-tabs[dark] > .sd-tab-label:hover {
  background-color: var(--dark-tabs-hover-bg-color);
  color: var(--dark-tabs-hover-text-color);
}
.sd-tabs > .sd-tab-radio:checked .sd-tab-label {
  z-index: 4;
}
.sd-tabs > .sd-tab-label::after,
.sd-tabs > .sd-tab-label::before {
  content: " ";
  pointer-events: none;
  position: absolute;
  display: block;
  height: 80%;
  width: 100%;
  bottom: 0px;
  z-index: -1;
  opacity: 0;
}
.sd-tabs > .sd-tab-radio:not(:checked) + .sd-tab-label:not(:hover) {
  box-shadow: -8px 0px 0 -7px rgba(0, 0, 0, 0.25);
  color: var(--tabs-text-color);
}
.sd-tabs[dark] > .sd-tab-radio:not(:checked) + .sd-tab-label:not(:hover) {
  color: var(--dark-tabs-text-color);
}
.sd-tabs > .sd-tab-radio:checked .sd-tab-label + .sd-tab-label,
.sd-tabs > .sd-tab-label:hover + .sd-tab-radio:not(:checked) + .sd-tab-label {
  box-shadow: -8px 0px 0 -7px rgba(0, 0, 0, 0);
}
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label:hover {
  /* transition: opacity ease .2s, box-shadow ease .2s;
  */
}
.sd-tabs > .sd-tab-radio + .sd-tab-label:hover::before,
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label::before {
  left: -100%;
  border-radius: 0 0 10px 0;
  opacity: 1;
  /* transition: opacity ease .2s, box-shadow ease .2s;
  */
}
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label::before {
  box-shadow: 14px 0.25em 0 -4px var(--tabs-selected-bg-color);
}
.sd-tabs[dark] > .sd-tab-radio:checked + .sd-tab-label::before {
  box-shadow: 14px 0.25em 0 -4px var(--dark-tabs-selected-bg-color);
}
.sd-tabs > .sd-tab-radio + .sd-tab-label:hover::after,
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label::after {
  right: -100%;
  border-radius: 0 0 0 10px;
  opacity: 1;
  /* transition: opacity ease .2s, box-shadow ease .2s;
  */
}
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label::after {
  box-shadow: -14px 0.25em 0 -4px var(--tabs-selected-bg-color);
}
.sd-tabs[dark] > .sd-tab-radio:checked + .sd-tab-label::after {
  box-shadow: -14px 0.25em 0 -4px var(--dark-tabs-selected-bg-color);
}
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label:hover,
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label {
  background-color: var(--tabs-selected-bg-color);
  color: #01304A;
  font-weight: bold;
  z-index: 4;
}
.sd-tabs[dark] > .sd-tab-radio:checked + .sd-tab-label:hover,
.sd-tabs[dark] > .sd-tab-radio:checked + .sd-tab-label {
  background-color: var(--dark-tabs-selected-bg-color);
  color: var(--dark-tabs-selected-text-color);
  z-index: 4;
}
.sd-tabs > .sd-tab-radio:not(:checked) + .sd-tab-label:hover::before {
  box-shadow: 14px 0.25em 0 -4px var(--tabs-hover-bg-color);
  opacity: 1;
  /* transition: all .2s;
  */
}
.sd-tabs[dark] > .sd-tab-radio:not(:checked) + .sd-tab-label:hover::before {
  box-shadow: 14px 0.25em 0 -4px var(--dark-tabs-hover-bg-color);
}
.sd-tabs > .sd-tab-radio:not(:checked) + .sd-tab-label:hover::after {
  box-shadow: -14px 0.25em 0 -4px var(--tabs-hover-bg-color);
  /* transition: all .2s;
  */
}
.sd-tabs[dark] > .sd-tab-radio:not(:checked) + .sd-tab-label:hover::after {
  box-shadow: -14px 0.25em 0 -4px var(--dark-tabs-hover-bg-color);
}
:root .sd-tabs > .sd-tab-radio + .sd-tab-label:first-of-type {
  box-shadow: -8px 0px 0 -7px rgba(0, 0, 0, 0);
}
.sd-tabs > .sd-tab-content {
  flex-basis: 100%;
  display: none;
  padding: 0.255rem;
  background-color: var(--tabs-selected-bg-color);
  box-shadow: inset 0px 0px 0 1px var(--tabs-selected-bg-color);
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  width: 100%;
  height: calc(100% - var(--tabs-height));
  overflow: auto;
}
.sd-tabs > .break {
  background-color: aqua;
  flex-basis: 100%;
}
.sd-tabs[dark] > .sd-tab-content {
  background-color: var(--dark-tabs-hover-bg-color);
  color: var(--dark-tabs-text-color);
  box-shadow: inset 0px 0px 0 1px var(--dark-tabs-selected-bg-color);
}
.sd-tabs > .sd-tab-radio:checked + .sd-tab-label + .sd-tab-content {
  display: block;
}
.sd-tabs > .sd-tab-label .sd-tab-desc {
  display: block;
  margin: 7px 5px 5px 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  font-size: 24px;
  flex: 1 1 0%;
}
.sd-tabs > .sd-tab-label .sd-tab-close:hover {
  background-color: #4c4c4c33;
}
.sd-tabs > .sd-tab-label .sd-tab-close {
  margin-left: auto;
}
.sd-tabs > .sd-tab-label::after,
.sd-tabs > .sd-tab-label::before {
  visibility: hidden;
}
@media (min-width: 600px) {
  .sd-tab-content {
    order: 999;
  }
  .sd-tabs {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .sd-tab-label {
    max-width: 150px;
    width: 150px;
    border-radius: 10px 10px 0 0;
  }
  .sd-tabs > .sd-tab-label::after,
  .sd-tabs > .sd-tab-label::before {
    visibility: visible;
  }
  .sd-tabs > .sd-tab-label:first-of-type {
    margin-left: 14px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
}

.video-wrapper {
  width: 100%;
  max-width: 1000px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 600px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  font-size: 30px;
}

input[type="range"] {
  -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type="range"]::-moz-range-progress {
  background: white;
}

.velocity {
  appearance: none;
  background: none;
  color: #FFBDA6;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.mute-btn i {
  background-color: none;
  color: white;
  font-size: 20px;
}

.react-pdf__Page__canvas {
  display: inline !important;
}

.player{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-left-yt {
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
  height: 220px;
  width: 389px;
  margin: auto;
}

.player-right-yt {
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
  height: 103px;
  width: 208px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis; 
}

@media ( -webkit-device-pixel-ratio: 1.25 ) {
  .MuiList-root .recharts-tooltip-wrapper {
    zoom: 1.25
  }
}

@media (-webkit-min-device-pixel-ratio: 1.1) {
  .MuiList-root .recharts-tooltip-wrapper {
    zoom: 1.25
  }
}
